import React, { FC, memo } from 'react'
import { IParentFormDialogProps } from '../types'
import ParentFormDialogRequest from './ParentFormDialogRequest'
import ParentFormDialogSuccess from './ParentFormDialogSuccess'
import { useParentFormDialog } from '../bll'

const ParentFormDialog: FC<IParentFormDialogProps> = (props) => {
  const { parentFormDialogRequest, parentFormDialogSuccess } = useParentFormDialog(props)

  return (
    <>
      <ParentFormDialogRequest
        open={parentFormDialogRequest.isActive}
        onClose={parentFormDialogRequest.closeDialog}
        parentFormDialogSuccess={parentFormDialogSuccess}
      />

      <ParentFormDialogSuccess
        open={parentFormDialogSuccess.isActive}
        onClose={parentFormDialogSuccess.closeDialog}
      />
    </>
  )
}

export default memo(ParentFormDialog)
