import { FC, memo } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Dialog } from '@ucheba/ui/components/Dialog/desktop'
import { IParentFormDialogSuccessProps } from '../types'

const ParentFormDialogSuccess: FC<IParentFormDialogSuccessProps> = (props) => {
  const { open, onClose } = props

  return (
    <Dialog open={open} onClose={onClose} size='small'>
      <Heading
        h2='УРА! Теперь твоя регистрация полностью завершена.'
        h3='Ждем тебя на мероприятии!'
        textAlign='center'
      />
    </Dialog>
  )
}

export default memo(ParentFormDialogSuccess)
