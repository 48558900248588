import React, { FC, memo } from 'react'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Radio } from '@ucheba/ui/components/Radio/desktop'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { TextField } from '@ucheba/ui/components/TextField/desktop'
import { ErrorText } from '@ucheba/ui/components/ErrorText/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { ErrorMessage, Field, Formik } from 'formik'
import { Dialog } from '@ucheba/ui/components/Dialog/desktop'
import { IParentFormDialogRequestProps } from '../types'
import { useParentFormDialogRequest } from '../bll'

const ParentFormDialogRequest: FC<IParentFormDialogRequestProps> = (props) => {
  const { onClose, open } = props

  const { initialValues, isStudent, isLoading, onSubmit } =
    useParentFormDialogRequest(props)

  return (
    <Dialog open={open} onClose={onClose} size='small'>
      <Heading
        h2='Спасибо за регистрацию!'
        h3='Остался один шаг до получения подарка!'
        textAlign='center'
      />

      <Text>
        Для участия в мероприятии понадобятся данные твоего родителя. Обрати внимание,
        прохождение профориентации и диагностики ЕГЭ доступны только старшеклассникам,
        пришедшим вместе с родителями.
      </Text>

      <Spacing spacing='xlarge' />

      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <Form>
              {!isStudent && (
                <>
                  <Grid spacing='small'>
                    <GridCol width='auto'>
                      <Field
                        as={Radio}
                        type='radio'
                        name={EDataKeys.isParent}
                        value='false'
                        label='Я старшеклассник'
                      />
                    </GridCol>

                    <GridCol width='auto'>
                      <Field
                        as={Radio}
                        type='radio'
                        name={EDataKeys.isParent}
                        value='true'
                        label='Я родитель'
                      />
                    </GridCol>
                  </Grid>

                  <Spacing spacing='xlarge' />
                </>
              )}

              {values[EDataKeys.isParent] === 'false' && (
                <div>
                  <Field
                    as={TextField}
                    name={EDataKeys.parentName}
                    label='Имя родителя'
                    required
                  />

                  <ErrorMessage component={ErrorText} name={EDataKeys.parentName} />

                  <Spacing spacing='xlarge' />

                  <Field
                    as={TextField}
                    name={EDataKeys.parentPhone}
                    type='tel'
                    label='Телефон родителя'
                    required
                  />

                  <ErrorMessage component={ErrorText} name={EDataKeys.parentPhone} />
                </div>
              )}

              <Spacing spacing='xlarge' />

              <Button
                block
                type='submit'
                color='orange'
                progress={isLoading}
                disabled={
                  (!values[EDataKeys.parentName] || !values[EDataKeys.parentPhone]) &&
                  values[EDataKeys.isParent] === 'false'
                }
              >
                Готово
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default memo(ParentFormDialogRequest)
