import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { useDispatch, useSelector } from 'react-redux'
import { profileSelectors, profileThunks } from '@ucheba/store/profile'
import { useCallback, useEffect, useMemo } from 'react'
import { ELoading } from '@ucheba/store/utils/response/types'
import { useDialog } from '@ucheba/ui/components/Dialog/bll'
import { useRouter } from 'next/router'
import { stringifyUrl } from '@ucheba/utils/helpers/router'
import { IUseParentFormDialog, IUseParentFormDialogRequest } from './types'
import {
  parentForm,
  parentFormDialogRequestId,
  parentFormDialogSuccessId,
} from './constants'

export const useParentFormDialog: IUseParentFormDialog = (props) => {
  const { exam } = props || {}
  const { id } = props || {}

  const profile = useSelector(profileSelectors.entity)
  const parentFormDialogRequest = useDialog(parentFormDialogRequestId + id)
  const parentFormDialogSuccess = useDialog(parentFormDialogSuccessId + id)
  const { asPath, replace, query } = useRouter()

  const isParent = useMemo(() => {
    if (profile) {
      return profile.isParent
    }

    return false
  }, [profile])

  useEffect(() => {
    if (profile && !isParent && query[parentForm] === String(id) && !exam) {
      setTimeout(() => {
        parentFormDialogRequest.openDialog()
      }, 600)
    }
  }, [isParent, profile])

  useEffect(() => {
    if (parentFormDialogRequest.isActive && query[parentForm]) {
      replace(
        stringifyUrl(asPath, { set: { ...query }, exclude: [parentForm] }),
        undefined,
        { shallow: true }
      )
    }
  }, [parentFormDialogRequest.isActive])

  return {
    parentFormDialogRequest,
    parentFormDialogSuccess,
  }
}

export const useParentFormDialogRequest: IUseParentFormDialogRequest = (props) => {
  const { parentFormDialogSuccess, onClose } = props
  const dispatch = useDispatch()
  const profile = useSelector(profileSelectors.entity)
  const profileLoading = useSelector(profileSelectors.loading)

  const isLoading = useMemo(() => {
    return profileLoading === ELoading.pending
  }, [profileLoading])

  const initialValues = useMemo(() => {
    return {
      [EDataKeys.isParent]: 'false',
      [EDataKeys.parentName]: profile?.parentName || '',
      [EDataKeys.parentPhone]: profile?.parentPhone || '',
    }
  }, [profile?.parentName, profile?.parentPhone])

  const onSubmit = useCallback(
    async (values: Record<string, any>): void => {
      const isParent = values[EDataKeys.isParent] === 'true'
      const parentName = values[EDataKeys.parentName]
      const parentPhone = values[EDataKeys.parentPhone]

      await dispatch(
        profileThunks.patch({
          data: {
            isParent,
            parentName,
            parentPhone,
          },
        })
      )

      await dispatch(profileThunks.get({}))

      onClose()

      parentFormDialogSuccess.openDialog()
    },
    [dispatch, onClose, parentFormDialogSuccess]
  )

  const isStudent = useMemo(() => {
    if (profile) {
      return typeof profile.isParent === 'boolean' && !profile.isParent
    }

    return false
  }, [profile])

  return {
    isLoading,
    initialValues,
    onSubmit,
    isStudent,
  }
}
